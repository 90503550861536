import Switch from '@mui/material/Switch';
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill"; // ES6
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { ATTRIBUTEGet } from "../../../redux/actions/Attribute/Attribute.actions";
import { BrandGet } from "../../../redux/actions/Brand/brand.actions";
import { getAllNestedCategories } from "../../../redux/actions/Category/Category.actions";
import { PRODUCTAdd, PRODUCTGet, PRODUCTUpdate } from "../../../redux/actions/Product/Product.actions";
import { PRODUCT_CATEGORYGet } from "../../../redux/actions/ProductCategory/ProductCategory.actions";
import CustomButton from "../../Utility/Button";
import { DashboardBox } from "../../Utility/DashboardBox";
import FileUpload from "../../Utility/FileUpload";
import { toastError } from "../../Utility/ToastUtils";
import { generateFilePath } from "../../Utility/utils";

function GeneralProduct() {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [sku, setSku] = useState("");
  const [mainCategoryArr, setMainCategoryArr] = useState([]);

  const [tags, setTags] = useState("");
  const [stock, setStock] = useState(0);
  const [despcription, setdespcription] = useState("");
  const [specifications, setSpecifications] = useState("");
  const [productImageStr, setProductImageStr] = useState("");
  const [price, setPrice] = useState(0);
  const [imageArr, setImageArr] = useState([{ image: "", imageAlt: "" }]);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaImage, setMetaImage] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [globalProductsArr, setGlobalProductsArr] = useState([]);


  const [isPopular, setIsPopular] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [isFeatured, setIsFeatured] = useState(false);
  const [isBestSeller, setIsBestSeller] = useState(false);
  const [freeShippingAvailable, setFreeShippingAvailable] = useState(false);

  const [brandId, setBrandId] = useState("");


  const [poitnerArr, setPoitnerArr] = useState([
    {
      name: ""
    }
  ]);



  const [selectedCategoryArr, setSelectedCategoryArr] = useState([]);


  const [additionalInfo, setAdditionalInfo] = useState([
    {
      name: "",
      value: "",
    }
  ]);





  const [mrp, setMrp] = useState(0);

  const brands = useSelector((state) => state.brand.brands);
  const categoryArr = useSelector((state) => state.category.categories);
  const productObj = useSelector((state) => state.product.productObj);
  const productsArr = useSelector((state) => state.product.products);

  useEffect(() => {
    dispatch(getAllNestedCategories());
    dispatch(BrandGet());
    dispatch(PRODUCTGet("nameOnly=true"));
  }, []);

  useEffect(() => {
    if (categoryArr && categoryArr.length > 0) {
      setMainCategoryArr([...categoryArr]);
    }
  }, [categoryArr]);

  useEffect(() => {
    if (productsArr && productsArr.length > 0) {
      let tempArr = productsArr
      if (productObj && productObj?._id) {
        tempArr = tempArr.filter(el => el._id != productObj?._id)
      }
      setGlobalProductsArr([...tempArr]);
    }
  }, [productsArr]);

  const handleBrandSelection = (obj, index) => {
    console.log(obj, "brand Obj");
    // tempArr[index].brandId = obj.value
    // tempArr[index].brandObj = obj


    // console.log(tempArr, "tempArr")
  };


  const handleFileSet = (value, index) => {

    let tempArr = imageArr;


    tempArr[index].image = value
    setImageArr([...tempArr]);
  };


  useEffect(() => {
    if (categoryArr && categoryArr.length > 0) {
      setMainCategoryArr([...categoryArr]);
    }
  }, [categoryArr]);





  const returnSelectedCategories = (arr) => {
    let new_selected_arr = arr.filter(el => el.checked)
    let subCategories = arr.reduce((acc, el) => [...acc, ...el.subCategoryArr.filter(el => el.checked)], [])
    if (subCategories?.length) {
      return [...new_selected_arr, ...returnSelectedCategories(subCategories)]
    }
    else {
      return [...new_selected_arr]
    }
  }





  const handleRenderNestedCategory = (arr, id, value) => {
    let tempArr = arr.map(el => {
      if (el._id == id) {
        el.checked = value
        return el
      }
      else {
        if (el.subCategoryArr && el.subCategoryArr.length > 0) {
          handleRenderNestedCategory(el.subCategoryArr, id, value)
        }
        else {
          return el
        }
      }
    })
    return tempArr;
  }




  const handleNestedCategoryCheckBoxEvent = (id, value) => {
    let tempCategoryArr = categoryArr.map(el => {
      if (el._id == id) {
        el.checked = value
        return el
      }
      else {
        if (el.subCategoryArr && el.subCategoryArr.length > 0) {
          el.subAttributesArr = handleRenderNestedCategory(el.subCategoryArr, id, value)
          return el
        }
        else {
          return el
        }
      }
    });
    setMainCategoryArr([...tempCategoryArr])
  }


  const handleRenderCheckboxCategory = (obj) => {
    return (
      <div className="col-12 mb-3" key={obj._id} style={{ marginLeft: `${obj.level + 5}px` }}>
        <input className="form-check-input pointer" checked={obj.checked} value={tags} onChange={(event) => handleNestedCategoryCheckBoxEvent(obj._id, event.target.checked)} type="checkbox" />
        <label style={{ paddingLeft: 5 }}>
          {obj.name}
        </label>
        {
          obj.checked && obj.subCategoryArr && obj.subCategoryArr.length > 0 && obj.subCategoryArr.map((el) => {
            return (
              handleRenderCheckboxCategory(el)
            )
          })
        }
      </div>
    )
  }









  const handleFileUpload = (value) => {
    setMetaImage(value)
  }

  const handleSubmit = () => {
    if (name == "") {
      toastError("Name is mandatory")
      return
    }

    else if (price == 0) {
      toastError("Price is mandatory")
      return
    }

    if (!brandId || brandId == "") {
      toastError("Brand is mandatory")
      return
    }


    else if (despcription == "") {
      toastError("Description is mandatory")
      return
    }
    else if (specifications == "") {
      toastError("Specification is mandatory")
      return
    }
    let cat_arr = returnSelectedCategories(mainCategoryArr);
    if (cat_arr && cat_arr.length == 0) {
      toastError("Category is mandatory")
      return
    }


    let obj = {
      categoryArr: cat_arr.map(el => { return { categoryId: el?._id } }),
      categoryId: cat_arr[cat_arr.length - 1]?._id,
      name,
      description: despcription,
      specification: specifications,
      productImageStr,
      sku,
      stock,
      brandId,
      additionalInfo,
      poitnerArr,
      tags,
      metaTitle,
      metaDescription,
      metaImage,
      isPopular,
      isNew,
      isFeatured,
      freeShippingAvailable,
      isBestSeller,
      price: parseInt(price),
      imageArr,
      productDescription,
      relatedProductsArr: relatedProducts.map(el => ({ productId: el._id }))
    };

    obj.mrp = parseInt(mrp);
    obj.stock = parseInt(stock)
    console.log("almost done")
    if (productObj) {
      dispatch(PRODUCTUpdate(obj, productObj._id));
    }
    else {
      dispatch(PRODUCTAdd(obj));
    }
  };

  useEffect(() => {
    if (productObj && productObj?._id) {
      console.log(productObj, "productObj")
      setName(productObj?.name);
      setSku(productObj?.sku);
      setdespcription(productObj?.description);
      setSpecifications(productObj?.specification);
      setMetaTitle(productObj?.metaTitle);
      setMetaDescription(productObj?.metaDescription);
      setMetaImage(productObj?.metaImage);
      setPrice(productObj?.price);
      setTags(productObj?.tags);
      setStock(productObj?.stock);
      setAdditionalInfo(productObj?.additionalInfo);
      setPoitnerArr(productObj?.poitnerArr);
      setImageArr(productObj?.imageArr);
      setIsPopular(productObj?.isPopular);
      setIsNew(productObj?.isNew);
      setIsFeatured(productObj?.isFeatured);
      setIsBestSeller(productObj?.isBestSeller);
      setBrandId(productObj?.brandId);
      setFreeShippingAvailable(productObj?.freeShippingAvailable)



      setProductDescription(productObj?.productDescription)
      {
        productObj?.relatedProductsArr && productObj?.relatedProductsArr.length > 0 &&
          setRelatedProducts(productObj?.relatedProductsArr.map(el => ({ ...el, label: el.name, value: el._id })))
      }
    }
  }, [productObj]);



  const handleCategorySelectOnInit = (selectedCategoryArrFromDB, categoryArr) => {
    let tempArr = categoryArr.map((el) => {
      if (selectedCategoryArrFromDB?.some((ele) => ele.categoryId == el._id)) {
        el.checked = true;
      }
      if (el.subCategoryArr) {
        handleCategorySelectOnInit(selectedCategoryArrFromDB, el.subCategoryArr);
      }
      return el;
    });
  };
  useEffect(() => {
    if (productObj && categoryArr) {
      handleCategorySelectOnInit(productObj.categoryArr, categoryArr);
    }
  }, [categoryArr, productObj]);




  useEffect(() => {
    if (productObj && categoryArr) {
      handleCategorySelectOnInit(productObj.categoryArr, categoryArr);
    }
  }, [categoryArr, productObj]);


  const handleImageObjAdd = () => {
    let tempArr = imageArr
    tempArr.push({ image: "", imageAlt: "" })
    console.log(tempArr, "asdas")
    setImageArr([...tempArr])
  }
  const handleImageObjRemove = () => {
    if (imageArr.length > 1) {
      let tempArr = imageArr.filter((el, index) => index != imageArr.length - 1);
      setImageArr([...tempArr])
    }
  }


  ////////////p n c generator
  const generateCombinations = (tempattributeArr) => {
    const combine = ([head, ...[headTail, ...tailTail]]) => {
      // https://stackoverflow.com/a/57015870
      if (!headTail) {
        return head?.map((el) => ({
          name: el.name,
          attributeValueArr: [...el.attributeValueArr],
        }))
      }
      const combined = headTail?.reduce((acc, x) => {
        return acc.concat(
          head.map((h) => ({
            name: `${h.name}-${x.name}`,
            attributeValueArr: [...h.attributeValueArr, ...x.attributeValueArr],
            price: 0,
          }))
        )
      }, [])
      return combine([combined, ...tailTail])
    }
    let finalArr = []
    tempattributeArr.forEach((el) => {
      if (el.checked) {
        let tempArr = el.attributeValueArr.filter((elx) => elx.checked)
        if (tempArr.length) {
          finalArr.push(
            tempArr.map((el) => ({
              name: el.name,
              attributeValueArr: [
                {
                  attributeId: el._id,
                },
              ],
              price: 0,
            }))
          )
        }
      }
    })
    if (finalArr.length) {
      finalArr = combine(finalArr)
      // setCombinationArr([...finalArr])
    } else {
      // setCombinationArr = []
    }

    // finalArr
    if (finalArr.length) {
      // console.log(el)

    }
  }





  const handleproductImageAltEntry = (value, index) => {
    console.log(value, index)
    let tempArr = imageArr;
    tempArr[index].imageAlt = value
    setImageArr([...tempArr]);
  };

  const handleFilterChecked = (arr) => {
    if (arr.length > 0 && arr) {
      return arr.map(el => {
        if (el.subCategoryArr && el.subCategoryArr.length > 0 && el.checked) {
          let tempArr = selectedCategoryArr;
          if (tempArr.some(el => el != el._id)) {
            tempArr.push(el._id)
          }
          setSelectedCategoryArr([...tempArr])
          return { ...el, categoryId: el._id, subCategoryArr: handleFilterChecked(el.subCategoryArr) };
        }
        else {
          if (el.checked) {
            let tempArr = selectedCategoryArr;
            if (tempArr.some(el => el != el._id)) {
              tempArr.push(el._id)
            }
            setSelectedCategoryArr([...tempArr])
            return { ...el, categoryId: el._id }
          }
        }
      })
    }
    else {
      return arr
    }
  }
  const handleAdditionalInfoAdd = () => {
    let tempArr = additionalInfo

    tempArr.push({
      name: "",
      value: "",
    })
    setAdditionalInfo([...tempArr])
  }

  const handleAdditionalInfoRemove = () => {
    let tempArr = additionalInfo
    if (tempArr && tempArr.length >= 1) {
      tempArr.pop()
    }

    setAdditionalInfo([...tempArr])
  }

  const handleAdditionalInfoEntry = (value, index, name) => {
    let tempArr = additionalInfo;

    tempArr[index][name] = value;

    setAdditionalInfo([...tempArr]);

  }




  const handlepointersArrAdd = () => {
    let tempArr = poitnerArr
    tempArr.push({
      name: "",
    })
    setPoitnerArr([...tempArr])
  }

  const handlepointersArrRemove = () => {
    let tempArr = poitnerArr
    if (tempArr && tempArr.length >= 1) {
      tempArr.pop()
    }
    setPoitnerArr([...tempArr])
  }

  const handlepointersArrEntry = (value, index) => {
    let tempArr = poitnerArr;
    tempArr[index].name = value;
    setPoitnerArr([...tempArr]);
  }

  const handlePrefillBrand = (id) => {
    if (brands && brands.length > 0) {
      let brandObj = brands.find(el => el._id == id)
      if (brandObj) {
        return { label: brandObj?.name, value: brandObj?._id }
      }
      else {
        return ""
      }
    }
    else {
      return ""
    }
  }

  return (
    <form className="form">
      <div className="row">
        <div className="col-12 col-md-8">
          <DashboardBox>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Product Information</h5>

              <div className="col-12 col-md-6 mb-3">
                <label>
                  Name <span className="red">*</span>
                </label>
                <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>
                  PRODUCT SKU <span className="red">*</span>
                </label>
                <input value={sku} onChange={(event) => setSku(event.target.value)} type="text" className="form-control" />
              </div>


            </div>
            <div className="row d-flex">
              <div className="border-bottom pb-3 mb-4 row">
                <h5 className="blue-1 mb-4">Main Category <span className="red">*</span></h5>
                {
                  mainCategoryArr && mainCategoryArr.length > 0 && mainCategoryArr.map(el => {
                    return (
                      handleRenderCheckboxCategory(el)
                    )
                  })
                }
              </div>
            </div>
            <div className="row d-flex">

              <h5 className="blue-1 mb-3">Description Pointers</h5>
              <div className="row">
                <div className="col-2 me-5">
                  <CustomButton btntype="button" ClickEvent={handlepointersArrAdd} isBtn noIcon btnName="+" />
                </div>
                <div className="col-2">
                  <CustomButton btntype="button" ClickEvent={handlepointersArrRemove} isBtn noIcon btnName="-" />
                </div>
              </div>
            </div>
            {
              poitnerArr && poitnerArr.length > 0 && poitnerArr.map((el, index) => {
                return (
                  <div key={index} className='row'>
                    <div className="col-12 col-md-12 mb-3">
                      <label>Name ({index + 1})</label>
                      <input type="text" value={el.name} onChange={(event) => handlepointersArrEntry(event.target.value, index, "name")} className="form-control" />
                    </div>
                  </div>
                )
              })
            }
            <div className="row d-flex">

              <h5 className="blue-1 mb-3">Additional Information</h5>
              <div className="row">
                <div className="col-2 me-5">
                  <CustomButton btntype="button" ClickEvent={handleAdditionalInfoAdd} isBtn noIcon btnName="+" />
                </div>
                <div className="col-2">
                  <CustomButton btntype="button" ClickEvent={handleAdditionalInfoRemove} isBtn noIcon btnName="-" />
                </div>
              </div>
            </div>
            {
              additionalInfo && additionalInfo.length > 0 && additionalInfo.map((el, index) => {
                return (
                  <div key={index} className='row'>

                    <div className="col-12 col-md-6 mb-3">

                      <label>Name ({index + 1})</label>
                      <input type="text" value={el.name} onChange={(event) => handleAdditionalInfoEntry(event.target.value, index, "name")} className="form-control" />
                    </div>

                    <div className="col-12 col-md-6 mb-3">

                      <label>value ({index + 1})</label>
                      <input type="text" value={el.value} onChange={(event) => handleAdditionalInfoEntry(event.target.value, index, "value")} className="form-control" />
                    </div>
                  </div>
                )
              })
            }



            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Brands</h5>
              <Select onChange={(value) => { setBrandId(value._id); console.log(value, "value") }} value={handlePrefillBrand(brandId)} options={brands && brands.length > 0 && brands.map((ele) => ({ ...ele, label: ele.name, value: ele._id }))} />

            </div>

            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Related Products</h5>
              <Select closeMenuOnSelect={false} onChange={(value) => setRelatedProducts(value)} value={relatedProducts} isMulti options={globalProductsArr && globalProductsArr.length > 0 && globalProductsArr.map((ele) => ({ ...ele, label: ele.name, value: ele._id }))} />
            </div>


            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Price Info</h5>
              <div className="col-12 col-md-6 mb-3">
                <label>
                  PRICE <span className="red">*</span>
                </label>
                <input type="number" min={0} value={price} onChange={(event) => setPrice(event.target.value)} className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>
                  Stock <span className="red">*</span>
                </label>
                <input type="number" min={0} value={stock} onChange={(event) => setStock(event.target.value)} className="form-control" />
              </div>
            </div>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Description <span className="red">*</span></h5>
              <div className="col-12 mb-3">
                <ReactQuill value={despcription != "" && despcription ? despcription : ""} theme="snow" onChange={(e) => setdespcription(e)} />
              </div>
            </div>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Specifications <span className="red">*</span></h5>
              <div className="col-12 mb-3">
                <ReactQuill value={specifications != "" && specifications ? specifications : ""} onChange={(e) => setSpecifications(e)} />
              </div>
            </div>
            <div className="col-12 mb-3">
              <label>TAGS (Coma seperated)</label>
              <input onChange={(e) => setTags(e.target.value)} value={tags} type="text" className="form-control" />
            </div>

            <div className="row">
              <h5 className="blue-1 mb-4">SEO info</h5>
              <div className="col-12 mb-3">
                <label>META TITLE</label>
                <input onChange={(e) => setMetaTitle(e.target.value)} value={metaTitle} type="text" className="form-control" />
              </div>
              <div className="col-12 mb-3">
                <label>META DESCRIPTION</label>
                <textarea onChange={(e) => setMetaDescription(e.target.value)} value={metaDescription} name="META DESCRIPTION" className="form-control" rows="3"></textarea>
              </div>
              <div className="col-12 mb-3">
                <label>META IMAGE (300X300)PX</label>

                {
                  metaImage != "" &&
                  <>
                    <br />
                    <br />
                    <img src={`${metaImage}`.includes("base64") ? metaImage : generateFilePath(metaImage)} style={{ height: 80 }} />
                    <br />
                    <br />
                  </>
                }

                <FileUpload onFileChange={handleFileUpload} />
              </div>
              <div className="col-12">
                <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
              </div>
            </div>
          </DashboardBox>
        </div>
        <div className="col-12 col-md-4">
          <DashboardBox>
            <div className="col-12 mb-3">
              <div className="row d-flex">

                <h5 className="blue-1 mb-3">Product Image Info</h5>
                <div className="row">
                  <div className="col-2 me-5">
                    <CustomButton btntype="button" ClickEvent={handleImageObjAdd} isBtn noIcon btnName="+" />
                  </div>
                  <div className="col-2">
                    <CustomButton btntype="button" ClickEvent={handleImageObjRemove} isBtn noIcon btnName="-" />
                  </div>
                </div>
              </div>
              {imageArr && imageArr.length > 0 && imageArr.map((el, index) => {
                return (
                  <div key={index} style={{ marginTop: 20, borderBottom: "grey 1px solid", paddingBottom: 15 }} className="row">
                    <div className="col-12">
                      <label>
                        Product Image<span className="red">*</span>
                      </label>
                      {
                        el.image != "" &&
                        <>
                          <br />
                          <br />
                          <img src={`${el.image}`.includes("base64") ? el.image : generateFilePath(el.image)} style={{ height: 80 }} />
                          <br />
                          <br />
                        </>
                      }


                      <FileUpload onFileChange={(val) => handleFileSet(val, index)} />
                    </div>
                    <div className="col-12">
                      <label>
                        Product Image alt<span className="red">*</span>
                      </label>
                      <input onChange={(e) => handleproductImageAltEntry(e.target.value, index)} value={el?.imageAlt} type="text" className="form-control" />
                    </div>

               

                  </div>
                )
              })}


<div className="row">
                   <div className="col-6">
                      <label>Is Featured</label>
                      <div>
                        <Switch defaultChecked={isFeatured} checked={isFeatured} onChange={() => setIsFeatured(!isFeatured)} />
                      </div>
                    </div>

                    <div className="col-6">
                      <label>Is New</label>
                      <div>
                        <Switch defaultChecked={isNew} checked={isNew} onChange={() => setIsNew(!isNew)} />
                      </div>
                    </div>


                    <div className="col-6">
                      <label>Is Popular</label>
                      <div>
                        <Switch defaultChecked={isPopular} checked={isPopular} onChange={() => setIsPopular(!isPopular)} />
                      </div>
                    </div>


                    <div className="col-6">
                      <label>Is Bestseller</label>
                      <div>
                        <Switch defaultChecked={isBestSeller} checked={isBestSeller} onChange={() => setIsBestSeller(!isBestSeller)} />
                      </div>
                    </div>
                    <div className="col-6">
                      <label>Free Shipping Available</label>
                      <div>
                        <Switch defaultChecked={freeShippingAvailable} checked={freeShippingAvailable} onChange={() => setFreeShippingAvailable(!freeShippingAvailable)} />
                      </div>
                    </div>
                    </div>
            </div>

          </DashboardBox>
        </div>
      </div>
    </form>
  );
}

export default GeneralProduct;
