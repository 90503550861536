import React,{useState,useEffect} from  "react";
import DataTable from "react-data-table-component";
import ActionIcon from "../../Utility/ActionIcon";
import CustomButton from "../../Utility/Button";
import SearchBox from "../../Utility/SearchBox";
import { DashboardTable } from "../../Utility/DashboardBox";
import { getAllOrders } from "../../../services/order.service";
import { MainOrderStatus } from "../../Utility/utils";
import moment from "moment";

function CompleteOrder({ name }) {
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        getOrder();
      }, []);

    const getOrder = async () => {
        try {
          const { data: res } = await getAllOrders();
          if (res) {
            setOrders(res.data.filter((el) => el.statusArr.some(ol =>ol.status == MainOrderStatus.DELIVERED && ol.status != MainOrderStatus.CANCELLED)).filter((el =>el.orderStatus != MainOrderStatus.CANCELLED )));
          }
        } catch (error) {
          console.error(error);
        }
      };
      const PendingOrder_columns = [
        {
          name: "SL",
          selector: (row, index) => index + 1,
          sortable: true,
        },
        {
          name: "Date",
          selector: (row) => moment(row?.createdAt).format('DD-MMM-YY HH:MM'),
        },
        {
          name: "Order ID",
      selector: (row) => row?.orderId,
      cell: (row) => row?.orderId,
      width: "200",
        },
        {
          name: "Name",
          selector: (row) =>     row?.addressObj?.firstName   +' '+    row?.addressObj?.lastName 
        },
        {
          name: "Payment Type",
          selector: (row) => row?.paymentMethod == 'COD'?'COD':'Online',
        },
        {
          name: "Total Amount",
          selector: (row) => row.totalAmount,
        },
        {
          name: "Order Status",
          button: true,
          width: "10%",
          cell: (row) => <CustomButton redBtn={row?.status == "CANCELLED"} greenBtn={row?.status != "CANCELLED"} btnName={row?.orderStatus} />,
        },
        {
          name: "Is Paid",
          button: true,
          width: "10%",
          cell: (row) => <CustomButton redBtn={row?.paymentObj?.paymentChk != 1} greenBtn={row?.paymentObj?.paymentChk == 1} btnName={row?.paymentObj?.paymentChk == 1 ? "PAID" : "PENDING"} />,
        },
        {
          name: "Action",
          cell: (row) => <CustomButton isLink btntype="button" path={`/Order/Sale-Detail?id=${row?._id}`} btnName={"View"} />,
        },
      ];
  const PendingOrder_data = [];

  return (
    <DashboardTable className="mt-4">
      <div className="d-flex gap-3 justify-content-between mb-4">
        <h5 className="blue-1 m-0">{name}</h5>
        <SearchBox extraClass='bg-white'/>
      </div>
      <DataTable
        columns={PendingOrder_columns}
        data={orders}
        pagination
        noDataComponent="No data available in table"
      />
    </DashboardTable>
  );
}

export default CompleteOrder;
